import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectLoggedIn, selectUserData } from '@sso/shared/redux';
import { useLogout, useProductNavigate } from '@sso/shared/hooks';

// used in email entry points to check if active auth session belongs to same user
export default function useCheckAuth(username: string) {
    const userData = useSelector(selectUserData);
    const loggedIn = useSelector(selectLoggedIn);

    const productNavigate = useProductNavigate();
    const logout = useLogout();

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        if (loggedIn) {
            if (userData.username === username) {
                productNavigate();
            } else {
                logout();
            }
        }
    }, []);
}
