import { lazy } from 'react';

import { routes, paths, ProductType, UserAuthorityTypes } from '@sso/shared/constants';
import { GuardedRoute } from '@sso/shared/components';

const signupRoutes: GuardedRoute[] = [
    {
        path: routes.signupPasswordCreation,
        component: lazy(() => import('@sso/signup/routes/SignupPasswordCreationPage')),
        guard: {
            routeParams: Object.values(ProductType),
            webAuth: false,
        },
    },
    {
        path: routes.signupProductSelect,
        component: lazy(() => import('@sso/signup/routes/SignupProductSelectPage')),
        guard: {
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.signup,
        component: lazy(() => import('@sso/signup/routes/SignupPage')),
        guard: {
            routeParams: Object.values(ProductType),
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.signupBusiness,
        component: lazy(() => import('@sso/signup/routes/SignupPage')),
        guard: {
            routeParams: Object.values(ProductType),
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.signupEmailVerification,
        component: lazy(() => import('@sso/signup/routes/SignupEmailVerificationPage')),
        guard: {
            routeParams: Object.values(ProductType),
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.signupOauth,
        component: lazy(() => import('@sso/signup/routes/SignupOauthPage')),
        guard: {
            routeParams: undefined, // enables params validation
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.signupOauthBusiness,
        component: lazy(() => import('@sso/signup/routes/SignupOauthPage')),
        guard: {
            routeParams: undefined, // enables params validation
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.signupProfile,
        component: lazy(() => import('@sso/signup/routes/SignupProfilePage')),
        guard: {
            routeParams: [ProductType.Cloud, ProductType.As],
            permissions: [UserAuthorityTypes.Owner],
            loggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.signupDomainEnrollment,
        component: lazy(() => import('@sso/signup/routes/SignupDomainEnrollmentPage')),
        guard: {
            routeParams: [ProductType.Cloud],
            permissions: [UserAuthorityTypes.Owner],
            loggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.signupUserEmailVerification,
        component: lazy(() => import('@sso/signup/routes/SignupUserEmailVerificationPage')),
        guard: {
            routeParams: [ProductType.Cloud, ProductType.Support],
            permissions: [UserAuthorityTypes.CloudAdmin],
            loggedIn: true,
            webAuth: false,
        },
    },
    {
        path: `${paths.signup}/*`,
        component: lazy(() => import('@sso/signup/routes/SignupRedirect')),
        guard: {
            webAuth: false,
        },
    },
];

export default signupRoutes;
