import { generatePath, matchRoutes } from 'react-router-dom';

import { Params, RouteParams, RouteProduct } from '@sso/shared/types';
import {
    navigation as navigationConst,
    fallback as fallbackConst,
    routes as routesConst,
    params as paramsConst,
    paths as pathConst,
    domainRoutes,
    RouteKey,
    Route,
} from '@sso/shared/constants';

export const mapRouteToPath = (params: RouteParams, routes: Route[]) =>
    routes
        .map(route => {
            try {
                return generatePath(route, params as Required<RouteParams>);
            } catch {
                return null;
            }
        })
        .filter(path => !!path);

export const getKeyPath = (
    routeKey?: RouteKey,
    params: Params = {} as Params,
    fallback: Route | Route[] = [],
) => {
    const pathParams = Object.entries(params).reduce(
        (p, [key, value]) => ({ ...p, [key]: value?.toLowerCase() }),
        {} as RouteParams,
    );

    const navigation = {
        ...(pathParams.product ? navigationConst[pathParams.product as RouteProduct] : {}),
        ...navigationConst.base,
    };

    const route = routeKey && navigation[routeKey as keyof typeof navigation];

    const defaultFallback = pathParams.product
        ? fallbackConst[pathParams.product]
        : [routesConst.signinProductSelect];

    const routes = [
        ...(Array.isArray(route) ? route : [route]),
        ...(Array.isArray(fallback) ? fallback : [fallback]),
        ...defaultFallback,
    ].filter(Boolean) as Route[];

    return mapRouteToPath(pathParams, routes)[0] as string;
};

export const matchPath = (pathname: string) => {
    const paths = [
        `${pathConst.password}${paramsConst.product}`,
        `${pathConst.signin}${paramsConst.product}`,
        `${pathConst.signup}${paramsConst.product}`,
        ...Object.values(domainRoutes),
        ...Object.values(routesConst),
    ].map(path => ({ path }));

    const [pathMatch] = matchRoutes(paths, pathname) || [];

    return pathMatch;
};

export const getPathParams = (pathname: string) => {
    const pathMatch = matchPath(pathname);

    return {
        product: pathMatch?.params?.product,
        domain: pathMatch?.params?.domain,
    };
};
