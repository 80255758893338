import { lazy } from 'react';

import { routes, paths, domainRoutes, ProductType } from '@sso/shared/constants';
import { GuardedRoute } from '@sso/shared/components';

const passwordRecoveryRoutes: GuardedRoute[] = [
    {
        path: routes.passwordForgot,
        component: lazy(() => import('@sso/password-recovery/routes/PasswordForgotPage')),
        guard: {
            routeParams: [ProductType.As, ProductType.Support],
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.passwordEmailVerification,
        component: lazy(
            () => import('@sso/password-recovery/routes/PasswordEmailVerificationPage'),
        ),
        guard: {
            routeParams: [ProductType.As, ProductType.Support],
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.passwordMfaVerification,
        component: lazy(() => import('@sso/password-recovery/routes/PasswordMfaVerificationPage')),
        guard: {
            routeParams: [ProductType.As, ProductType.Support],
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: routes.passwordReset,
        component: lazy(() => import('@sso/password-recovery/routes/PasswordResetPage')),
        guard: {
            routeParams: [ProductType.As, ProductType.Support],
            notLoggedIn: true,
            webAuth: false,
        },
    },
    {
        path: domainRoutes.passwordForgot,
        component: lazy(() => import('@sso/password-recovery/routes/PasswordForgotPage')),
        guard: {
            routeParams: [ProductType.Cloud],
            notLoggedIn: true,
        },
    },
    {
        path: domainRoutes.passwordEmailVerification,
        component: lazy(
            () => import('@sso/password-recovery/routes/PasswordEmailVerificationPage'),
        ),
        guard: {
            routeParams: [ProductType.Cloud],
            notLoggedIn: true,
        },
    },
    {
        path: domainRoutes.passwordMfaVerification,
        component: lazy(() => import('@sso/password-recovery/routes/PasswordMfaVerificationPage')),
        guard: {
            routeParams: [ProductType.Cloud],
            notLoggedIn: true,
        },
    },
    {
        path: domainRoutes.passwordReset,
        component: lazy(() => import('@sso/password-recovery/routes/PasswordResetPage')),
        guard: {
            routeParams: [ProductType.Cloud],
            notLoggedIn: true,
        },
    },
    {
        path: `${paths.password}/*`,
        component: lazy(() => import('@sso/password-recovery/routes/PasswordRedirect')),
        guard: {
            notLoggedIn: true,
        },
    },
];

export default passwordRecoveryRoutes;
