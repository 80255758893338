import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { Route, RouteKey } from '@sso/shared/constants';
import { selectRouteParams } from '@sso/shared/redux';
import { getKeyPath } from '@sso/shared/utils';
import { Params } from '@sso/shared/types';

export type Payload = {
    fallback?: Route | Route[];
    params?: Params;
    key?: RouteKey;
};

export default function useKeyPath({ fallback, params, key }: Payload = {}) {
    const routeParams = useSelector(selectRouteParams);

    return useMemo(
        () => getKeyPath(key, { ...routeParams, ...params }, fallback),
        [key, params, fallback, routeParams],
    );
}
