import { useMemo } from 'react';
import { Entries, ShapeWithValue } from '@sso/shared/types';

import { GuardResponse, GuardValidator, GuardData, Guard } from './RouteGuard.props';
import { validator } from './RouteGuard.utils';

const useGuardValidator = <D extends GuardData, G extends Partial<ShapeWithValue<D, Guard>>>(
    data: D,
    guard: G,
): [boolean, GuardResponse<G>] => {
    const access = useMemo(
        () =>
            (Object.entries(validator) as Entries<GuardValidator<D, G>>).reduce(
                (access, [key, validate]) => ({
                    ...(key in guard && { [key]: validate(data[key], guard[key]) }),
                    ...access,
                }),
                {},
            ),
        [data, guard],
    );

    const allowed = useMemo(() => Object.values(access).every(Boolean), [access]);

    return [allowed, access];
};

export default useGuardValidator;
