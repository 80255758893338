import { getCookiesDomain, parseSearch, serialize } from '@ovpn-ui/utils';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

import { ProductRedirect, ProductType } from '@sso/shared/constants';
import { validDomain } from '@sso/shared/validation';
import { AnyRecord } from '@sso/shared/types';

import { getPathParams } from './navigation';
import * as ls from './localStore';

export const staticCloudDomains = [
    'cloud-billing',
    'sso-backend',
    'opsportal',
    'saml-idp',
    'support',
    'cloud',
];

export const getRedirectDomain = (redirect: string): string | null => {
    try {
        const { hostname } = new URL(redirect);
        return hostname?.split('.')[0];
    } catch {
        return null;
    }
};

export const getRedirectProduct = (redirect: string): ProductType | null => {
    try {
        const { pathname, hostname } = new URL(redirect);
        const domain = hostname?.split('.')[0];

        if (
            (domain === 'sso-backend' && pathname.includes('zendesklogin')) ||
            redirect.startsWith(window._sso_env_.SUPPORT_HOST) ||
            domain === 'opsportal'
        ) {
            return ProductType.Support;
        }

        if (
            redirect.startsWith(window._sso_env_.AS_PORTAL_HOST) ||
            redirect.startsWith(window._sso_env_.PRODUCT_HOST) ||
            pathname.startsWith('/us2')
        ) {
            return ProductType.As;
        }

        return ProductType.Cloud;
    } catch {
        return null;
    }
};

const validateRedirect = (_redirect?: string): string | null => {
    if (!_redirect) {
        return null;
    }

    try {
        const redirect = new URL(decodeURIComponent(_redirect)).toString();
        const cookiesDomain = getCookiesDomain();

        return redirect.includes(cookiesDomain) ? redirect : null;
    } catch {
        return null;
    }
};

export const validateProduct = (_product?: string): ProductType | null => {
    if (!_product) {
        return null;
    }

    const product = _product?.toUpperCase() as ProductType;
    return Object.values(ProductType).includes(product) ? product : null;
};

export const validateDomain = (domain?: string): string | null =>
    domain && domain.length >= 3 && domain.length <= 40 && !validDomain(domain) ? domain : null;

const validateConfig = ({
    redirect,
    product,
    domain,
    ...rest
}: {
    redirect?: string;
    product?: string;
    domain?: string;
}) => {
    const config = {
        redirect: validateRedirect(redirect),
        product: validateProduct(product),
        domain: validateDomain(domain),
        ...rest,
    };

    if (config.redirect) {
        config.product = config.product || getRedirectProduct(config.redirect);
    }

    return omitBy(config, isNil);
};

export function getPathProductParams(pathname: string) {
    const { product, domain } = getPathParams(pathname);

    return validateConfig({
        product,
        domain,
    });
}

export function getStorageProductParams() {
    const storedParams = ls.getProductParams() || {};
    const product = ls.getProduct();
    const domain = ls.getDomain();

    return validateConfig({
        ...storedParams,
        product,
        domain,
    });
}

export function getSearchProductParams(search: string) {
    const queryParams = parseSearch(search);

    return validateConfig({
        ...omit(queryParams, ['return', 'return_to', 'domain', 'tenant']),
        redirect: (queryParams.return || queryParams.return_to) as string,
        domain: (queryParams.domain || queryParams.tenant) as string,
    });
}

export function getProductRedirect({
    storedRedirect,
    forceRedirect,
    userDomains,
    product,
    domain,
}: {
    forceRedirect?: boolean;
    storedRedirect?: string;
    userDomains: string[];
    product?: ProductType;
    domain?: string;
}) {
    if (forceRedirect) {
        return (
            storedRedirect || (product && ProductRedirect[product]) || window._sso_env_.PRODUCT_HOST
        );
    }

    if (product === ProductType.Cloud) {
        const redirectDomain = storedRedirect && getRedirectDomain(storedRedirect);
        const allowedDomains = [...staticCloudDomains, ...userDomains];

        // selected domain is valid
        if (domain && userDomains.includes(domain)) {
            // return url is valid
            if (redirectDomain && allowedDomains.includes(redirectDomain)) {
                // return url is a cloud generic link
                if (redirectDomain === 'cloud') {
                    return storedRedirect.replace('cloud', domain);
                }

                // return url is an allowed static redirect or matches selected domain
                if (staticCloudDomains.includes(redirectDomain) || redirectDomain === domain) {
                    return storedRedirect;
                }

                // return url doesn't match selected domain
                return ProductRedirect[ProductType.Cloud].replace('cloud', domain);
            }

            // return url is absent/invalid
            return ProductRedirect[ProductType.Cloud].replace('cloud', domain);
        }

        // selected domain is absent/invalid & return url is valid
        if (redirectDomain && allowedDomains.includes(redirectDomain)) {
            return storedRedirect;
        }

        // selected domain is absent/invalid & return url is absent/invalid
        return ProductRedirect[ProductType.Cloud];
    }

    const redirectProduct = storedRedirect && getRedirectProduct(storedRedirect);

    if (redirectProduct && product === redirectProduct) {
        return storedRedirect;
    }

    return (product && ProductRedirect[product]) || window._sso_env_.PRODUCT_HOST;
}

export const cleanQueryParams = (queryParams: AnyRecord): AnyRecord =>
    omit(queryParams, [
        'explicitProductSelect',
        'forceRedirect',
        'return_to',
        'redirect',
        'product',
        'return',
        'tenant',
        'domain',
        'amznt',
        'fpr',
    ]);

export function makeProductRedirect(redirect: string, queryParams: AnyRecord): void {
    ls.clearProductParams();
    ls.clearAwsUserLinked();
    ls.clearAmazonToken();
    ls.clearFprToken();

    setTimeout(() => {
        window.location.assign(`${redirect}${serialize(cleanQueryParams(queryParams))}`);
    });
}
