import { OAuthType, ProductType } from '@sso/shared/constants';
import { validateDomain } from '@sso/shared/utils';

import { GuardValidator } from './RouteGuard.props';

export const permissionsValidator: GuardValidator['permissions'] = (data, requirement): boolean => {
    if (!Array.isArray(data.authorities)) return false;

    if (Array.isArray(requirement)) {
        return requirement.some(role => data.authorities.includes(role));
    }

    if (typeof requirement === 'function') {
        try {
            return requirement(data);
        } catch {
            return false;
        }
    }

    return false;
};

export const routeParamsValidator: GuardValidator['routeParams'] = (
    data,
    requirement = Object.values(ProductType),
): boolean =>
    (!data.provider ||
        Object.values(OAuthType).includes(data.provider.toUpperCase() as OAuthType)) &&
    (!data.product || requirement.includes(data.product.toUpperCase() as ProductType)) &&
    (!data.domain || !!validateDomain(data.domain));

export const notLoggedInValidator: GuardValidator['notLoggedIn'] = (data): boolean =>
    data.isRestrained || !data.loggedIn;

export const loggedInValidator: GuardValidator['loggedIn'] = (loggedIn): boolean => loggedIn;

export const webAuthValidator: GuardValidator['webAuth'] = (isWebAuth, requirement?) =>
    requirement ? true : !isWebAuth;

export const validator: GuardValidator = {
    permissions: permissionsValidator,
    routeParams: routeParamsValidator,
    notLoggedIn: notLoggedInValidator,
    loggedIn: loggedInValidator,
    webAuth: webAuthValidator,
};
