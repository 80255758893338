import React from 'react';
import { Navigate, NavigateProps, useLocation } from 'react-router-dom';

function SearchNavigate({ to, ...props }: NavigateProps) {
    const { search } = useLocation();

    return <Navigate to={`${to}${search}`} {...props} />;
}

export default SearchNavigate;
