import styled from 'styled-components';
import { colors } from '@ovpn-ui/styles';

import { Box, shadows } from '@sso/shared/core';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 24px;

    word-break: break-word;

    background-color: ${colors.neutralWhite};
    border: 1px solid ${colors.neutralBorder};
    border-radius: 8px;

    &:hover {
        background-color: ${colors.neutralOffwhite};
        border: 1px solid ${colors.neutralBorderHover};
        cursor: pointer;
    }

    &:active {
        box-shadow: ${shadows.focusedBlue};
    }
` as typeof Box;
