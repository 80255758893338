import { lazy } from 'react';

import { routes, domainRoutes, ProductType, paths } from '@sso/shared/constants';
import { GuardedRoute } from '@sso/shared/components';

import signinDomainTotpSetup from '@sso/signin/routes/SigninDomainTotpSetup';

const signinRoutes: GuardedRoute[] = [
    {
        path: paths.signin,
        component: lazy(() => import('@sso/signin/routes/SigninOutlet')),
        children: [
            ...signinDomainTotpSetup,
            {
                path: routes.signinAws,
                component: lazy(() => import('@sso/signin/routes/SigninAwsPage')),
                guard: {
                    routeParams: [ProductType.Cloud, ProductType.As],
                    webAuth: false,
                },
            },
            {
                path: routes.signinPrevCreds,
                component: lazy(() => import('@sso/signin/routes/SigninPrevCredsPage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                    webAuth: false,
                },
            },
            {
                path: routes.signinSamlError,
                component: lazy(() => import('@sso/signin/routes/SigninSamlErrorPage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                },
            },
            {
                path: routes.signinOauth,
                component: lazy(() => import('@sso/signin/routes/SigninOauthPage')),
                guard: {
                    routeParams: undefined, // enables params validation
                    notLoggedIn: true,
                },
            },
            {
                path: routes.signinProductSelect,
                component: lazy(() => import('@sso/signin/routes/SigninProductSelectPage')),
                guard: {
                    webAuth: false,
                },
            },
            {
                path: routes.signinDomainSelect,
                component: lazy(() => import('@sso/signin/routes/SigninDomainSelectPage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                },
            },
            {
                path: routes.signinDomainRecovery,
                component: lazy(() => import('@sso/signin/routes/SigninDomainRecoveryPage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                    webAuth: false,
                },
            },
            {
                path: routes.signinDomainRecoveryConfirmation,
                component: lazy(
                    () => import('@sso/signin/routes/SigninDomainRecoveryConfirmationPage'),
                ),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                    webAuth: false,
                },
            },
            {
                path: routes.signinPasswordVerification,
                component: lazy(() => import('@sso/signin/routes/SigninPasswordVerificationPage')),
                guard: {
                    routeParams: Object.values(ProductType),
                    webAuth: false,
                },
            },
            {
                path: routes.signinPasswordCreation,
                component: lazy(() => import('@sso/signin/routes/SigninPasswordCreationPage')),
                guard: {
                    routeParams: Object.values(ProductType),
                    webAuth: false,
                },
            },
            {
                path: routes.signinPasswordCreationConfirmation,
                component: lazy(
                    () => import('@sso/signin/routes/SigninPasswordCreationConfirmationPage'),
                ),
                guard: {
                    routeParams: Object.values(ProductType),
                    webAuth: false,
                },
            },
            {
                path: domainRoutes.signinSaml,
                component: lazy(() => import('@sso/signin/routes/SigninSamlPage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                },
            },
            {
                path: domainRoutes.signinChallenge,
                component: lazy(() => import('@sso/signin/routes/SigninChallengePage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                },
            },
            {
                path: routes.signinChallenge,
                component: lazy(() => import('@sso/signin/routes/SigninChallengePage')),
                guard: {
                    routeParams: [ProductType.As, ProductType.Support],
                    notLoggedIn: true,
                    webAuth: false,
                },
            },
            {
                path: domainRoutes.signinUsername,
                component: lazy(() => import('@sso/signin/routes/SigninUsernamePage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                },
            },
            {
                path: domainRoutes.signinPassword,
                component: lazy(() => import('@sso/signin/routes/SigninPasswordPage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                },
            },
            {
                path: routes.signin,
                component: lazy(() => import('@sso/signin/routes/SigninPage')),
                guard: {
                    routeParams: [ProductType.As, ProductType.Support],
                    notLoggedIn: true,
                    webAuth: false,
                },
            },
            {
                path: domainRoutes.signin,
                component: lazy(() => import('@sso/signin/routes/SigninPage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                },
            },
            {
                path: domainRoutes.signinDomainUserActivation,
                component: lazy(() => import('@sso/signin/routes/SigninDomainUserActivationPage')),
                guard: {
                    routeParams: [ProductType.Cloud],
                    notLoggedIn: true,
                },
            },
            {
                index: true,
                path: `${paths.signin}/*`,
                component: lazy(() => import('@sso/signin/routes/SigninRedirect')),
            },
        ],
    },
];

export default signinRoutes;
