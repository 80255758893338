import { ButtonGroup as CoreButtonGroup } from '@ovpn-ui/core';
import { styledIf } from '@ovpn-ui/styles';
import styled from 'styled-components';

import { withBoxStyles, mapProps as defaultMapProps, BoxOwnProps } from '@sso/shared/core/Box';

const mapProps = (props: BoxOwnProps) => ({
    fullWidth: props.fullWidth,
    ...defaultMapProps(props),
});

const ButtonGroup = styled(CoreButtonGroup)`
    ${styledIf('fullWidth')`
        flex-wrap: wrap;
    `}
` as typeof CoreButtonGroup;

export default withBoxStyles(ButtonGroup, mapProps);
