import { useCallback } from 'react';

import { useAppDispatch, useAppStore } from '@sso/shared/store';
import { MfaType } from '@sso/shared/constants';
import {
    useLoginTokenExchangeMutation,
    selectPendingSignupPathKey,
    restrainProductRedirect,
    LoginChallengeResponse,
} from '@sso/shared/redux';

import { useNavigate } from '../navigation';

export type MetaData = {
    trustThisDevice?: boolean;
    username?: string;
};

export default function useLoginTokenExchange() {
    const [tokenExchange] = useLoginTokenExchangeMutation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const store = useAppStore();

    return useCallback(
        async (
            {
                trustedDeviceAllowed,
                pendingChallenges,
                registrationType,
                nextChallenge,
                entityType,
                flowToken,
            }: LoginChallengeResponse,
            { trustThisDevice, username }: MetaData = {},
        ) => {
            if (pendingChallenges?.[0]?.challengeType === MfaType.Totp) {
                return navigate({
                    key: 'signinDomainTotpQRCode',
                    state: {
                        mfaCode: pendingChallenges[0].details.code,
                        mfaUri: pendingChallenges[0].details.uri,
                        flowToken,
                        username,
                    },
                });
            }

            if (nextChallenge && nextChallenge !== MfaType.None) {
                return navigate({
                    key: 'signinChallenge',
                    state: {
                        challenge: nextChallenge,
                        accountType: entityType,
                        trustedDeviceAllowed,
                        flowToken,
                        username,
                    },
                });
            }

            try {
                dispatch(restrainProductRedirect(true));

                const res = await tokenExchange({ flowToken, trustThisDevice }).unwrap();

                if (res.missedChallenge && res.missedChallenge !== MfaType.None) {
                    return navigate({
                        key: 'signinChallenge',
                        state: {
                            trustedDeviceAllowed: res.trustedDeviceAllowed,
                            challenge: res.missedChallenge,
                            accountType: res.entityType,
                            flowToken: res.flowToken,
                        },
                    });
                }

                const pendingSignup = selectPendingSignupPathKey(store.getState());

                if (pendingSignup) {
                    return navigate({
                        key: pendingSignup,
                        state: {
                            registrationType,
                            allowed: true,
                        },
                    });
                }
            } finally {
                dispatch(restrainProductRedirect(false));
            }
        },
        [dispatch, navigate, store, tokenExchange],
    );
}
