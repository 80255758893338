import { createSelector } from '@reduxjs/toolkit';

import { hasUsedCreds, isCloudAuth, ls } from '@sso/shared/utils';
import { ProductType } from '@sso/shared/constants';
import { RouteProduct } from '@sso/shared/types';
import { RootState } from '@sso/shared/store';

export const selectProductParams = (state: RootState) => state.product.productParams ?? {};
export const selectIsInitialized = (state: RootState) => state.product.isInitialized;
export const selectIsRedirecting = (state: RootState) => state.product.isRedirecting;
export const selectIsRestrained = (state: RootState) => state.product.isRestrained;
export const selectProduct = (state: RootState) => state.product.product;
export const selectDomain = (state: RootState) => state.product.domain;

export const selectRouteParams = createSelector(
    [selectProduct, selectDomain],
    (product, domain) => ({
        product: product?.toLowerCase() as RouteProduct,
        domain,
    }),
);

export const selectRegistrationParams = createSelector(
    [selectProductParams],
    ({ irclickid, fpr }) => ({
        ...(irclickid && { irclickid }),
        ...(fpr && { fpr }),
    }),
);

export const selectHasUsedCreds = createSelector(
    [selectProduct],
    product =>
        product === ProductType.Cloud && hasUsedCreds() && !isCloudAuth() && !ls.getAmazonToken(),
);
