export const errorCodes = {
    UNKNOWN: '3-0-0',
    MAINTENANCE: '3-0-1',

    internal: {
        UNKNOWN: '3-10-1',

        // Auth
        AUTHENTICATION_ERROR: '3-11-1',
        AUTHORIZATION_ERROR: '3-11-2',
        REGISTRATION_NOT_COMPLETED: '3-11-3',

        // Validation
        REQUEST_VALIDATION_ERROR: '3-12-1',
        CAPTCHA_VALIDATION_ERROR: '3-12-2',
        EMAIL_VALIDATION_ERROR: '3-12-3',
        ACCOUNT_EXISTS: '3-12-4',
        ACCOUNT_NOT_EXISTS: '3-12-5',
        ACTIVATION_DATA_DOESNT_EXIST: '3-12-6',
        PASSWORD_INPUTS_DO_NOT_MATCH: '3-12-7',
        PASSWORD_INPUTS_MATCH: '3-12-8',
        BAD_CREDENTIALS: '3-12-9',
        INVALID_RESET_PASSWORD_TOKEN: '3-12-10',
        AUTH_INVALID_PASSWORD_VALUE: '3-12-11',
        ENTITY_NOT_FOUND: '3-12-12',
        EMAIL_RESEND_NOT_ALLOWED_YET: '3-12-13',
        NO_DIGIT_CODE_PRESENT: '3-12-14',
        INCORRECT_DIGIT_CODE: '3-12-15',
        ACCOUNT_BANNED: '3-12-16',
        TENANT_NAME_EXISTS: '3-12-17',
        OWNER_NOT_FOUND: '3-12-18',
        INVALID_MFA_METHOD: '3-12-19',
        WRONG_PASSWORD_FORCE_LOGOUT: '3-12-24',
        WRONG_PASSWORD: '3-12-25',
        RESET_PASSWORD_CODE_EXPIRED: '3-12-27',
        RESET_PASSWORD_TOKEN_EXPIRED: '3-12-28',
        MEMBER_RESET_PASSWORD_CODE_EXPIRED: '3-12-44',
        MEMBER_RESET_PASSWORD_TOKEN_EXPIRED: '3-12-45',
        ACTIVATION_CODE_NOT_ENTERED: '3-12-30',
        RECENT_PASSWORD_NOT_ALLOWED: '3-12-31',
        SAME_PASSWORD_NOT_ALLOWED: '3-12-33',
        INVALID_ACTIVATION_TOKEN: '3-12-49',

        VALIDATION_SERVICE_FAIL: '3-26-1',
        RELAY_CONNECTION_FAIL: '3-15-5',
        BLACKLISTED_EMAIL: '3-12-35',
        PROHIBITED_EMAIL: '3-12-56',
        PASSWORD_FORGOT_NOT_ALLOWED: '3-12-57',
        INVALID_CLIENT_TIME: '3-12-61',
        PASSWORD_LOGIN_DISABLED: '3-12-64',
        OAUTH_LOGIN_FAIL: '3-12-65',
        AWS_MARKETPLACE_ERROR: '3-210-1',
        BILLING_GENERIC_ERROR: '3-29-2',
        AWS_BILLING_LINK_ERROR: '3-29-3',
        AWS_TENANT_CREATE_ERROR: '3-12-68',

        BUSINESS_SIGNUP_ERROR: '3-12-69',

        // Account
        ACCOUNT_CREATION_FAIL: '3-13-1',
        ACCOUNT_DELETE_BLOCKED_BY_LICENSE: '3-13-5',
        ACCOUNT_DELETE_BLOCKED_BY_SUBSCRIPTION: '3-13-6',

        // SAML
        INVALID_SAML_CONFIGURATION: '3-14-2',
        SAML_CONFIGURATION_NOT_FOUND: '3-14-6',
        SP_METADATA_GENERATION_FAILED: '3-14-8',
        IDP_UNABLE_TO_READ_METADATA: '3-14-9',
        IDP_INVALID_CONFIGURATION: '3-14-10',

        LDAP_SERVER_NOT_RESPONDING: '3-15-4',

        // DOMAIN
        BILLING_UNREACHABLE: '3-2-1',
        PROHIBITED_DOMAIN: '3-12-54',
        OTP_PASSWORD_SETUP_REQUIRED: '3-16-1',
        FORCED_PASSWORD_RESET: '3-16-4',
        INVALID_MEMBER: '3-16-5',
        ACCOUNT_SUSPENDED: '3-16-6',
        MEMBER_EXISTS_IN_ACCOUNT: '3-12-59',
    },

    integration: {
        GENERIC_INTEGRATION_ERROR: '3-20-0',
        X_VERIFY_IO: '3-21-0',
    },
} as Readonly<{
    UNKNOWN: string;
    MAINTENANCE: string;
    internal: Record<string, string>;
    integration: Record<string, string>;
}>;

export const signupInvalidationErrorCodes = [
    errorCodes.internal.INVALID_ACTIVATION_TOKEN,
    errorCodes.internal.ACCOUNT_EXISTS,
] as ReadonlyArray<string>;

export const silentErrorCodes = [
    errorCodes.internal.ACCOUNT_DELETE_BLOCKED_BY_SUBSCRIPTION,
    errorCodes.internal.ACCOUNT_DELETE_BLOCKED_BY_LICENSE,

    errorCodes.internal.INVALID_ACTIVATION_TOKEN,
    errorCodes.internal.ACTIVATION_CODE_NOT_ENTERED,
    errorCodes.internal.OTP_PASSWORD_SETUP_REQUIRED,
    errorCodes.internal.FORCED_PASSWORD_RESET,
] as ReadonlyArray<string>;
