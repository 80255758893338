export type UserAuthorityTypes = (typeof UserAuthorityTypes)[keyof typeof UserAuthorityTypes];
export type OwnerAuthType = (typeof OwnerAuthType)[keyof typeof OwnerAuthType];
export type AccountType = (typeof AccountType)[keyof typeof AccountType];
export type OAuthType = (typeof OAuthType)[keyof typeof OAuthType];
export type AuthType = (typeof AuthType)[keyof typeof AuthType];
export type MfaType = (typeof MfaType)[keyof typeof MfaType];

export const CaptchaKind = {
    EGRC_INV: 'EGRC-INV',
    EGRC_CB: 'EGRC-CB',
    CFT_INV: 'TRNSTL',
} as const;

export const MfaType = {
    Email: 'EMAIL',
    Totp: 'TOTP',
    None: 'NONE',
} as const;

export const OAuthType = {
    Microsoft: 'MICROSOFT',
    Google: 'GOOGLE',
} as const;

export const OwnerAuthType = {
    Internal: 'INTERNAL',
    ...OAuthType,
} as const;

export const AuthType = {
    ...OwnerAuthType,
    Ldap: 'LDAP',
    Saml: 'SAML',
} as const;

export const OAuthProviderName = {
    [OAuthType.Microsoft]: 'Microsoft',
    [OAuthType.Google]: 'Google',
} as Record<OAuthType, string>;

export const AccountType = {
    Resource: 'RESOURCE',
    Account: 'ACCOUNT',
};

export const UserAuthorityTypes = {
    EnrolledOwner: 'ENROLLED_OWNER',
    Owner: 'OWNER',
    CloudAdmin: 'PRODUCT_ADMIN',
    Member: 'MEMBER',
} as const;
