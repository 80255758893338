import { useCallback } from 'react';

import { useLdapLoginMutation, InternalLoginPayload, selectProduct } from '@sso/shared/redux';
import { useAppStore } from '@sso/shared/store';

import { useGtm } from '../gtm';

import useLoginTokenExchange from './useLoginTokenExchange';

export default function useLdapLogin() {
    const [ldapLogin] = useLdapLoginMutation();

    const loginTokenExchange = useLoginTokenExchange();
    const store = useAppStore();
    const gtm = useGtm();

    return useCallback(
        async (payload: Omit<InternalLoginPayload, 'product'>) => {
            const product = selectProduct(store.getState())!;

            const res = await ldapLogin({ ...payload, product }).unwrap();
            await loginTokenExchange(res, { username: payload.username });

            gtm.sendAuthEvent({ event: gtm.events.login });
        },
        [loginTokenExchange, ldapLogin, store, gtm],
    );
}
