import React, { Suspense, useEffect } from 'react';

import { ErrorBoundary } from '@sso/shared/components';
import { Spinner } from '@sso/shared/core';

type RouteWrapperProps = {
    fallback: React.ReactNode;
    children: React.ReactNode;
};

function RouteWrapper({ fallback = <Spinner delayed absolute />, children }: RouteWrapperProps) {
    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <ErrorBoundary>
            <Suspense fallback={fallback}>{children}</Suspense>
        </ErrorBoundary>
    );
}

export default RouteWrapper;
