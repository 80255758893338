import { useCallback } from 'react';

import { errorCodes } from '@sso/shared/constants';
import { useAppStore } from '@sso/shared/store';
import { ApiError } from '@sso/shared/types';
import { ls } from '@sso/shared/utils';
import {
    useGetUserActivationTokenMutation,
    useGetUserForceResetTokenMutation,
    useInternalLoginMutation,
    InternalLoginPayload,
    selectProduct,
} from '@sso/shared/redux';

import { useNavigate } from '../navigation';
import { useGtm } from '../gtm';

import useLoginTokenExchange from './useLoginTokenExchange';

export default function useInternalLogin() {
    const [getUserActivationToken] = useGetUserActivationTokenMutation();
    const [getUserForceResetToken] = useGetUserForceResetTokenMutation();
    const [internalLogin] = useInternalLoginMutation();

    const loginTokenExchange = useLoginTokenExchange();
    const navigate = useNavigate();
    const store = useAppStore();
    const gtm = useGtm();

    return useCallback(
        async (payload: Omit<InternalLoginPayload, 'product'>) => {
            const { username, password, domain } = payload;

            try {
                const product = selectProduct(store.getState())!;
                const awsToken = ls.getAmazonToken();

                const res = await internalLogin({ ...payload, product, awsToken }).unwrap();
                await loginTokenExchange(res, { username });

                gtm.sendAuthEvent({ event: gtm.events.login });
            } catch (e) {
                const error = e as ApiError;

                switch (error?.errorCode) {
                    case errorCodes.internal.ACTIVATION_CODE_NOT_ENTERED: {
                        navigate({
                            key: 'signupEmailVerification',
                            state: {
                                allowed: true,
                                email: username,
                                password,
                            },
                        });

                        break;
                    }
                    case errorCodes.internal.FORCED_PASSWORD_RESET: {
                        const { token } = await getUserForceResetToken({
                            domain: domain!,
                            username,
                            password,
                        }).unwrap();

                        navigate({
                            key: 'passwordReset',
                            state: {
                                forced: true,
                                username,
                                token,
                            },
                        });

                        break;
                    }
                    case errorCodes.internal.OTP_PASSWORD_SETUP_REQUIRED: {
                        const { token } = await getUserActivationToken({
                            domain: domain!,
                            username,
                            password,
                        }).unwrap();

                        navigate({
                            key: 'signinDomainUserActivation',
                            state: {
                                confirmationToken: token,
                                username,
                            },
                        });

                        break;
                    }
                    default:
                        break;
                }

                throw e;
            }
        },
        [
            getUserForceResetToken,
            getUserActivationToken,
            loginTokenExchange,
            internalLogin,
            navigate,
            store,
            gtm,
        ],
    );
}
