import { useCallback } from 'react';
import { useNavigate as useNavigateOriginal } from 'react-router-dom';

import { Route, RouteKey } from '@sso/shared/constants';
import { selectRouteParams } from '@sso/shared/redux';
import { AnyRecord, Params } from '@sso/shared/types';
import { useAppStore } from '@sso/shared/store';
import { getKeyPath } from '@sso/shared/utils';

export type Payload = {
    fallback?: Route | Route[];
    replace?: boolean;
    state?: AnyRecord;
    params?: Params;
    key?: RouteKey;
};

export default function useNavigate() {
    const navigate = useNavigateOriginal();
    const store = useAppStore();

    return useCallback(
        ({ key, params, state, fallback, replace }: Payload = {}) => {
            const routeParams = selectRouteParams(store.getState());

            const pathname = getKeyPath(key, { ...routeParams, ...params }, fallback);

            navigate(pathname, { replace, state });
        },
        [navigate, store],
    );
}
