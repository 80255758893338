import { storeFactory } from '@ovpn-ui/utils';

export const PREFIX = 'open-vpn-sso';

const PRODUCT_PARAMS_KEY = 'product-params';
const PRODUCT_KEY = 'product';
const DOMAIN_KEY = 'domain';

const AMAZON_USER_LINKED_KEY = 'amzn-user-linked';
const AMAZON_TOKEN_KEY = 'amzn-m-token';
const FPR_TOKEN_KEY = 'fpr-token';

export const {
    set: setProductParams,
    get: getProductParams,
    clear: clearProductParams,
} = storeFactory(PRODUCT_PARAMS_KEY, PREFIX);

export const {
    set: setDomain,
    get: getDomain,
    clear: clearDomain,
} = storeFactory(DOMAIN_KEY, PREFIX);

export const {
    set: setProduct,
    get: getProduct,
    clear: clearProduct,
} = storeFactory(PRODUCT_KEY, PREFIX);

export const {
    set: setAmazonToken,
    get: getAmazonToken,
    clear: clearAmazonToken,
} = storeFactory(AMAZON_TOKEN_KEY, PREFIX);

export const {
    set: setAwsUserLinked,
    get: getAwsUserLinked,
    clear: clearAwsUserLinked,
} = storeFactory(AMAZON_USER_LINKED_KEY, PREFIX);

export const {
    set: setFprToken,
    get: getFprToken,
    clear: clearFprToken,
} = storeFactory(FPR_TOKEN_KEY, PREFIX);

export const clearLocalStorage = () => {
    clearProductParams();
    clearAwsUserLinked();
    clearAmazonToken();
    clearFprToken();
    clearProduct();
    clearDomain();
};
