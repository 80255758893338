import { makeAssert, serialize } from '@ovpn-ui/utils';

import { isCloudAuth, unicodeBtoa } from '@sso/shared/utils';
import { MfaType, AccountType } from '@sso/shared/constants';
import { AnyRecord } from '@sso/shared/types';
import { apiSlice } from '@sso/shared/store';

const assert = makeAssert('authApi');

export type InternalLoginPayload = {
    captchaToken: string;
    awsToken?: string;
    username: string;
    password: string;
    product: string;
    domain?: string;
};

export type LoginChallengePayload = {
    challengeSecret: string;
    flowToken: string;
};

export type LoginChallengeResponse = {
    trustedDeviceAllowed?: boolean;
    registrationType?: string;
    entityType?: AccountType;
    nextChallenge?: MfaType;
    flowToken: string;
    pendingChallenges?: {
        challengeType: MfaType;
        details: AnyRecord;
    }[];
};

export type LoginTokenExchangePayload = {
    trustThisDevice?: boolean;
    flowToken: string;
};

export type LoginTokenExchangeResponse = Partial<{
    trustedDeviceAllowed: boolean;
    missedChallenge: MfaType;
    entityType: AccountType;
    flowToken: string;
}>;

export type GetFlowTokenPayload = {
    username: string;
    password: string;
    domain: string;
};

export type GetFlowTokenResponse = {
    token: string;
};

export const authApi = apiSlice
    .injectEndpoints({
        endpoints: builder => ({
            internalLogin: builder.mutation<LoginChallengeResponse, InternalLoginPayload>({
                query({ captchaToken, username, password, product, domain, awsToken }) {
                    assert(!!captchaToken, 'internalLogin: Expected a valid captchaToken');
                    assert(!!username, 'internalLogin: Expected a valid username');
                    assert(!!password, 'internalLogin: Expected a valid password');
                    assert(!!product, 'internalLogin: Expected a valid product');

                    return {
                        url: '/multi-auth/internal/login',
                        method: 'POST',
                        data: {
                            ...(domain && { domain }),
                            ...(awsToken && { awsToken }),
                            password: unicodeBtoa(password),
                            login: username,
                            captchaToken,
                            product,
                        },
                    };
                },
            }),
            ldapLogin: builder.mutation<
                LoginChallengeResponse,
                Omit<InternalLoginPayload, 'awsToken'>
            >({
                query({ captchaToken, username, password, product, domain }) {
                    assert(!!captchaToken, 'internalLogin: Expected a valid captchaToken');
                    assert(!!username, 'internalLogin: Expected a valid username');
                    assert(!!password, 'internalLogin: Expected a valid password');
                    assert(!!product, 'internalLogin: Expected a valid product');

                    return {
                        url: '/multi-auth/ldap/login',
                        method: 'POST',
                        data: {
                            ...(domain && { domain }),
                            password: unicodeBtoa(password),
                            login: username,
                            captchaToken,
                            product,
                        },
                    };
                },
            }),
            loginChallenge: builder.mutation<LoginChallengeResponse, LoginChallengePayload>({
                query({ challengeSecret, flowToken }) {
                    assert(!!challengeSecret, 'loginChallenge: Expected a valid challengeSecret');
                    assert(!!flowToken, 'loginChallenge: Expected a valid flowToken');

                    return {
                        url: '/multi-auth/login/challenge',
                        method: 'POST',
                        data: {
                            challengeSecret,
                            flowToken,
                        },
                    };
                },
            }),
            loginTokenExchange: builder.mutation<
                LoginTokenExchangeResponse,
                LoginTokenExchangePayload
            >({
                query({ flowToken, trustThisDevice }) {
                    assert(!!flowToken, 'loginTokenExchange: Expected a valid flowToken');

                    const isAppLogin = isCloudAuth();
                    const queryParams = serialize({ ...(isAppLogin && { isAppLogin }) });

                    return {
                        url: `/multi-auth/login/token-exchange${queryParams}`,
                        method: 'POST',
                        data: {
                            ...(trustThisDevice && { trustThisDevice }),
                            flowToken,
                        },
                    };
                },
            }),
            refresh: builder.mutation<void, void>({
                query() {
                    return {
                        url: '/auth/refresh',
                        method: 'PUT',
                    };
                },
            }),
            logout: builder.mutation<void, void>({
                query() {
                    return {
                        url: '/auth/logout',
                        method: 'DELETE',
                    };
                },
            }),
            getUserActivationToken: builder.mutation<GetFlowTokenResponse, GetFlowTokenPayload>({
                query({ username, domain, password }) {
                    assert(!!username, 'getUserActivationToken: Expected a valid username');
                    assert(!!password, 'getUserActivationToken: Expected a valid password');
                    assert(!!domain, 'getUserActivationToken: Expected a valid domain');

                    return {
                        url: '/members/activation/otp/confirm',
                        method: 'POST',
                        data: {
                            username,
                            password,
                            domain,
                        },
                    };
                },
            }),
            getUserForceResetToken: builder.mutation<GetFlowTokenResponse, GetFlowTokenPayload>({
                query({ username, domain, password }) {
                    assert(!!username, 'getUserForceResetToken: Expected a valid username');
                    assert(!!password, 'getUserForceResetToken: Expected a valid password');
                    assert(!!domain, 'getUserForceResetToken: Expected a valid domain');

                    return {
                        url: '/auth/domain/password/force-reset/confirm-code',
                        method: 'POST',
                        data: {
                            digitCode: password,
                            username,
                            domain,
                        },
                    };
                },
            }),
        }),
    })
    .enhanceEndpoints({
        addTagTypes: ['auth-options', 'profile', 'profile-template'],
        endpoints: {
            logout: {
                invalidatesTags: ['auth-options', 'profile', 'profile-template'],
            },
        },
    });

export const {
    useLoginTokenExchangeMutation,
    useLoginChallengeMutation,
    useInternalLoginMutation,
    useLdapLoginMutation,
    useGetUserActivationTokenMutation,
    useGetUserForceResetTokenMutation,
    useRefreshMutation,
    useLogoutMutation,
} = authApi;
