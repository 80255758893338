import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AnyRecord } from '@sso/shared/types';

export default function useResetState() {
    const { pathname, state } = useLocation();
    const navigate = useNavigate();

    return useCallback(
        (reset: AnyRecord) =>
            navigate(pathname, {
                replace: true,
                state: {
                    ...state,
                    ...reset,
                },
            }),
        [navigate, pathname, state],
    );
}
