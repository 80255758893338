import styled from 'styled-components';

import { Box } from '@sso/shared/core';

const FlexForm = styled(Box).attrs({ forwardedAs: 'form' })`
    display: flex;
    flex-direction: column;
` as typeof Box;

export default FlexForm;
