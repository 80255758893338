import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { selectIsRestrained, selectLoggedIn, selectUserData } from '@sso/shared/redux';
import { isCloudAuth } from '@sso/shared/utils';
import { RouteParams } from '@sso/shared/types';

import { GuardData } from './RouteGuard.props';

const useGuardDataSelector = (): GuardData => {
    const isRestrained = useSelector(selectIsRestrained);
    const loggedIn = useSelector(selectLoggedIn);
    const userData = useSelector(selectUserData);

    const routeParams = useParams<RouteParams>();

    const webAuth = isCloudAuth();

    return useMemo(
        (): GuardData => ({
            notLoggedIn: { loggedIn, isRestrained },
            permissions: userData,
            routeParams,
            loggedIn,
            webAuth,
        }),
        [isRestrained, loggedIn, userData, routeParams, webAuth],
    );
};

export default useGuardDataSelector;
