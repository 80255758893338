export const gtmEvents = {
    productRedirect: 'sso/navigation/product-redirect',
    dataTransfer: 'sso/data-transfer',
    sessionStart: 'sso/session/start',
    sessionEnd: 'sso/session/end',
    navigation: 'sso/navigation',

    ssoScimConfigured: 'sso/scim/configured',
    ssoScimDisabled: 'sso/scim/disabled',
    ssoScimEnabled: 'sso/scim/enabled',
    ssoScimDeleted: 'sso/scim/deleted',

    ssoAwsEntry: 'sso/aws/entry',
    ssoAwsLinkError: 'sso/aws/link-error',

    abTestingStart: 'abtestingstart',

    signupButtonClicked: 'signupbuttonclicked',
    signupEmailVerified: 'signupemailverified',
    createOpenVpnId: 'createopenvpnid',
    signupOauth: 'signupoauth',

    supportNewAccountSignup: 'supportnewaccountsignup',
    cloudNewAccountSignup: 'cloudnewaccountsignup',
    asNewAccountSignup: 'asnewaccountsignup',

    otpPasswordFinalize: 'otppasswordfinalize',
    passwordForceReset: 'passwordforcereset',
    mfaSetupFinalize: 'mfasetupfinalize',
    mfaVerification: 'mfaverification',
    signinOauth: 'signinoauth',
    loginNext: 'loginnext',
    login: 'login',
} as const;
