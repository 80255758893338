import styled, { keyframes } from 'styled-components';
import { styledIf } from '@ovpn-ui/styles';

import { Spinner as CoreSpinner } from '@ovpn-ui/core';

const showAfterDelay = keyframes`
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const Spinner = styled(CoreSpinner)<{ $absolute: boolean; $delayed: boolean }>`
    ${styledIf('$absolute')`
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;
    `}

    ${styledIf('$delayed')`
        opacity: 0;

        animation: 500ms ${showAfterDelay};
        animation-fill-mode: forwards;
    `}
`;
