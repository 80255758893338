import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { UserAuthorityTypes, ProductType } from '@sso/shared/constants';
import { AnyRecord, UserData, GtmEventData } from '@sso/shared/types';

export function sendGtmSignupEvent(eventData: GtmEventData, userData: UserData) {
    const { profile = {}, ...data } = eventData;

    window.dataLayer.push({
        ...(userData.credentialsId && { credentials_id: userData.credentialsId }),
        ...omitBy(profile, isNil),
        ...omitBy(data, isNil),
    });
}

export function sendGtmAuthEvent(
    eventData: GtmEventData,
    userData: UserData,
    product?: ProductType,
) {
    window.dataLayer.push({
        ...(userData.credentialsId && { credentials_id: userData.credentialsId }),
        ...(userData.roles.cloudAdmin && { role_type: UserAuthorityTypes.CloudAdmin }),
        ...(userData.roles.member && { role_type: UserAuthorityTypes.Member }),
        ...(userData.roles.owner && { role_type: UserAuthorityTypes.Owner }),
        ...omitBy(eventData, isNil),
        product,
    });
}

export function sendGtmOauthEvent(
    eventData: GtmEventData,
    userData: UserData,
    product?: ProductType,
) {
    window.dataLayer.push({
        ...(userData.username && { email: userData.username }),
        ...(userData.credentialsId && { credentials_id: userData.credentialsId }),
        ...(userData.roles.cloudAdmin && { role_type: UserAuthorityTypes.CloudAdmin }),
        ...(userData.roles.member && { role_type: UserAuthorityTypes.Member }),
        ...(userData.roles.owner && { role_type: UserAuthorityTypes.Owner }),
        ...omitBy(eventData, isNil),
        product,
    });
}

export function sendGtmEvent(eventData: GtmEventData) {
    window.dataLayer.push(eventData);
}

export function setGtmData(data: AnyRecord) {
    window.dataLayer.push(data);
}
