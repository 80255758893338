import React from 'react';
import styled from 'styled-components';
import { colors } from '@ovpn-ui/styles';

import { Box } from '@sso/shared/core';

export const Container = styled(Box)`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
` as typeof Box;

export const Step = styled.div`
    min-width: 38px;
    height: 4px;

    background-color: ${colors.neutralLight};
    border-radius: 4px;
`;

export const Steps = styled.div<{ active?: number; children: React.ReactNode }>`
    display: flex;
    justify-content: center;

    column-gap: 8px;

    ${Step}:nth-child(${t => t.active}) {
        background-color: ${colors.brandOrange};
    }
`;
