import { createSelector } from '@reduxjs/toolkit';
import { isTrue } from '@ovpn-ui/utils';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';

import { UserAuthorityTypes, ProductType, AuthType } from '@sso/shared/constants';
import { AnyRecord, UserData } from '@sso/shared/types';
import { isCloudAuth } from '@sso/shared/utils';
import { RootState } from '@sso/shared/store';

import { selectProduct } from '../product';

export const selectToken = (state: RootState) => state.auth.token;

export const selectDecodedToken = createSelector(selectToken, (token): AnyRecord | null => {
    if (typeof token !== 'string') {
        return null;
    }

    try {
        return jwtDecode(token);
    } catch {
        return null;
    }
});

export const selectLoggedIn = createSelector([selectDecodedToken], jwt => {
    if (jwt === null) return false;

    const now = Date.now().valueOf() / 1000;
    const expired = now > jwt.exp;
    return !expired;
});

export const selectUserData = createSelector(selectDecodedToken, (jwt): UserData => {
    const availableLoginModes = get(jwt, ['available_login_modes'], AuthType.Internal);
    const authType = get(jwt, ['auth_type'], AuthType.Internal);
    const credentialsId = get(jwt, ['credentials_id'], '');
    const betaAccess = get(jwt, ['beta_access'], false);
    const authorities = get(jwt, ['authorities'], []);
    const tenant = get(jwt, ['client_domain'], null);
    const tenants = get(jwt, ['tenants'], '');
    const username = get(jwt, ['login'], '');
    const tags = get(jwt, ['tags'], []);

    const domains = [...new Set([tenant, ...tenants.split(',')])].filter(Boolean);
    const authOptions = availableLoginModes.split(',').filter(Boolean);

    const product = domains.length ? ProductType.Cloud : null;

    // auth
    const microsoft = authOptions.includes(AuthType.Microsoft);
    const internal = authOptions.includes(AuthType.Internal);
    const google = authOptions.includes(AuthType.Google);
    const saml = authOptions.includes(AuthType.Saml);
    const ldap = authOptions.includes(AuthType.Ldap);
    const multiple = authOptions.length > 1;

    // roles
    const enrolledOwner = authorities.includes(UserAuthorityTypes.EnrolledOwner);
    const owner = enrolledOwner || authorities.includes(UserAuthorityTypes.Owner);
    const cloudAdmin = authorities.includes(UserAuthorityTypes.CloudAdmin);
    const member = authorities.includes(UserAuthorityTypes.Member);

    // permissions
    const cloudApi = !!domains.length && isTrue(betaAccess);

    return {
        credentialsId,
        authorities,
        authOptions,
        authType,
        username,
        product,
        domains,
        tags,
        roles: {
            enrolledOwner,
            owner,
            cloudAdmin,
            member,
        },
        auth: {
            multiple,
            microsoft,
            internal,
            google,
            saml,
            ldap,
        },
        permissions: {
            cloudApi,
        },
    };
});

export const selectPendingSignupPathKey = createSelector(
    [selectProduct, selectLoggedIn, selectUserData],
    (product, loggedIn, { tags, roles }) => {
        if (product && loggedIn && roles.owner && !isCloudAuth()) {
            if (product !== ProductType.Support && tags.includes(product)) {
                return 'signupProfile';
            }

            if (product === ProductType.Cloud && !roles.enrolledOwner) {
                return 'signupDomainEnrollment';
            }
        }

        return null;
    },
);
