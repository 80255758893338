import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { parseSearch } from '@ovpn-ui/utils';

import { OAuthType, ProductType } from '@sso/shared/constants';
import { AnyRecord } from '@sso/shared/types';

export default function useRoute<
    State extends AnyRecord = AnyRecord,
    Search extends AnyRecord = AnyRecord,
>(): {
    params: {
        product?: ProductType;
        provider?: OAuthType;
        domain?: string;
    };
    pathname: string;
    search: Search;
    state: State;
} {
    const { pathname, search: searchQuery, state } = useLocation();

    const search = useMemo(() => parseSearch(searchQuery), [searchQuery]);
    const params = useParams();

    return useMemo(
        () => ({
            params: {
                ...params,
                ...(params?.provider && { provider: params.provider.toUpperCase() as OAuthType }),
                ...(params?.product && { product: params.product.toUpperCase() as ProductType }),
            },
            search: {
                ...search,
                ...(search.email && { email: decodeURIComponent(search.email as string) }),
            } as Search,
            state: state || ({} as State),
            pathname,
        }),
        [params, pathname, search, state],
    );
}
