import React from 'react';
import { useRoutes, RouteObject } from 'react-router-dom';

import { GuardedRouterProps, GuardedRoute, PageRoute, RedirectRoute } from './GuardedRouter.props';
import RouteWrapper from './RouteWrapper';
import RouteGuard from './RouteGuard';

const mapRoute =
    (fallback: React.ReactNode) =>
    ({ path, index, ...route }: GuardedRoute): RouteObject => {
        if ((route as PageRoute).component) {
            const { component: Component, children, guard } = route as PageRoute;

            const element =
                guard || path?.includes('/:') ? (
                    <RouteGuard guard={guard} key={path}>
                        <RouteWrapper fallback={fallback}>
                            <Component />
                        </RouteWrapper>
                    </RouteGuard>
                ) : (
                    <RouteWrapper fallback={fallback} key={path}>
                        <Component />
                    </RouteWrapper>
                );

            return {
                ...(index ? { index } : { children: children?.map(mapRoute(fallback)) }),
                element,
                path,
            };
        }

        return {
            element: (route as RedirectRoute).redirect,
            index,
            path,
        };
    };

function GuardedRouter({ fallback, routes }: GuardedRouterProps) {
    return useRoutes(routes.map(mapRoute(fallback)));
}

export default GuardedRouter;
