import { lazy } from 'react';

import { domainRoutes, ProductType, paths, params } from '@sso/shared/constants';
import { GuardedRoute } from '@sso/shared/components';

const signinDomainTotpSetupRoutes: GuardedRoute[] = [
    {
        path: `${paths.signin}${params.product}${params.domain}/mfa/totp`,
        component: lazy(() => import('./SigninDomainTotpOutlet')),
        guard: {
            routeParams: [ProductType.Cloud],
            notLoggedIn: true,
        },
        children: [
            {
                path: domainRoutes.signinDomainTotpQRCode,
                component: lazy(
                    () => import('./SigninDomainTotpQRCodePage/SigninDomainTotpQRCodePage'),
                ),
            },
            {
                path: domainRoutes.signinDomainTotpCode,
                component: lazy(
                    () => import('./SigninDomainTotpCodePage/SigninDomainTotpCodePage'),
                ),
            },
            {
                path: domainRoutes.signinDomainTotpFinalize,
                component: lazy(
                    () => import('./SigninDomainTotpFinalizePage/SigninDomainTotpFinalizePage'),
                ),
            },
        ],
    },
];

export default signinDomainTotpSetupRoutes;
