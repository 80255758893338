import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppDispatch } from '@sso/shared/store';
import { paths } from '@sso/shared/constants';
import { Spinner } from '@sso/shared/core';

import { RouteGuardProps } from './RouteGuard.props';
import { accessDenied } from './RouteGuard.actions';

import useGuardDataSelector from './useGuardDataSelector';
import useGuardValidator from './useGuardValidator';

// applies basic route params validation in case when guard isn't specified but route contains params
export const defaultGuard = {
    routeParams: undefined,
};

export default function RouteGuard({ guard = defaultGuard, children }: RouteGuardProps) {
    const dispatch = useAppDispatch();

    const data = useGuardDataSelector();
    const [allowed, access] = useGuardValidator(data, guard);

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        if (!allowed) {
            dispatch(accessDenied(access));
        }
    }, [allowed, access]);

    if (
        ('routeParams' in access && !access.routeParams) ||
        ('webAuth' in access && !access.webAuth)
    ) {
        return <Navigate to={paths.notFound} replace />;
    }

    if (!allowed) {
        return <Spinner absolute />;
    }

    return <>{children}</>;
}
