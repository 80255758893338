import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { GuardedRoute, SearchNavigate } from '@sso/shared/components';
import { paths, routes } from '@sso/shared/constants';

import passwordRecoveryRoutes from '@sso/password-recovery/routes';
import signupRoutes from '@sso/signup/routes';
import signinRoutes from '@sso/signin/routes';

const commonRoutes: GuardedRoute[] = [
    {
        path: '/',
        component: lazy(() => import('./Layout')),
        children: [
            {
                path: '/',
                redirect: <Navigate to={paths.signin} replace />,
            },
            {
                path: '/*',
                redirect: <Navigate to={paths.notFound} replace />,
            },
            {
                path: paths.samlError,
                redirect: <SearchNavigate to="/signin/cvpn/saml-error" replace />,
            },
            {
                path: paths.domainEnrollment,
                redirect: <Navigate to="/signup/cvpn/domain-enrollment" replace />,
            },
            {
                path: routes.notFound,
                component: lazy(() => import('./NotFoundPage')),
            },
            {
                path: routes.logout,
                component: lazy(() => import('./LogoutPage')),
            },
            ...passwordRecoveryRoutes,
            ...signupRoutes,
            ...signinRoutes,
        ],
    },
];

export default commonRoutes;
